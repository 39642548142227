import React from "react";
import Bright from "@mui/icons-material/Brightness4Outlined";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Medal from "../../Images/medal_small.png";

function HistoryIconsFct(props) {
  const { count, scale } = props; // Destructure count and scale from props
  let stars = Math.round(count * scale); // Calculate the number of stars based on count and scale

  const items = []; // Array to store the icons to be rendered
  let color = "red"; // Default color for the icons

  // Determine color based on the number of stars
  switch (stars) {
    case 0:
      color = "green";
      break;
    case 1:
      color = "blue";
      break;
    case 2:
      color = "orange";
      break;
    case 3:
      color = "red";
      break;
    case 4:
      color = "magenta";
      break;
    case 5:
      color = "#550A21";
      break;
    default:
      break;
  }

  // If no stars, push an ArrowRightAlt icon into items array
  if (stars === 0) {
    items.push(
      <ArrowRightAlt style={{ color: color }} key={0} fontSize="small" />,
    );
  } else {

    // For each star, push a Bright or Medal icon into items array
    for (let i = 0; i < stars; i++) {
      if (stars === 5) {
        items.push(<img src={Medal} key={i} alt="Medal" />);
      } else {
        items.push(
          <Bright style={{ color: color }} key={i} fontSize="small" />,
        );
      }
    }
  }

  return <div>{items}</div>; // Render the icons
}

export default HistoryIconsFct; // Export the HistoryIconsFct component as the default export
