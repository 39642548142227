import HistoryContainerFct from "./HistoryContainerFct";
//import { WordContext } from '../Contexts/WordContext';
import { useContext } from "react";
import APIURLMaker from "../APIURLMaker";

function HistoryTodayFct(props) {
  // Call HistoryContainerFct with specific parameters and assign the result to item
  let item = HistoryContainerFct(
    APIURLMaker("history/GetHistoryToday"), // Create URL for fetching today's history
    "History (Today) - Latest Top", // Title for the history container
  );
  return item; // Return the item created by HistoryContainerFct
}

export default HistoryTodayFct; // Export the HistoryTodayFct function as the default export
