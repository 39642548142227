import React, { Component } from "react";
import PropTypes from "prop-types";
import WordTypeIcon from "../Meanings/WordTypeIcon";

// Represents a tab with a label and icon, highlights the active tab, and calls the onClick function with its label when clicked
class Tab extends Component {
  // Define prop types for type checking
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  // Handle click event
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label); // Call onClick function with the label
  };

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active"; // Add active class if the tab is active
    }

    return (
      <li className={className} onClick={onClick}>
        {label} <WordTypeIcon wordType={label} />{" "} {/* Render label and icon */}
        {/* label and icon of the tab */}
      </li>
    );
  }
}

export default Tab;
