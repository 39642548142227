import APIURLMaker from "../APIURLMaker";
import HistoryContainerFct from "./HistoryContainerFct";

function HistoryAllTimesFct(props) {
  // Calling HistoryContainerFct function component with parameters to fetch history data
  let item = HistoryContainerFct(
    APIURLMaker("history/GetHistoryAllTimes"), // Generating API URL for fetching all-time history data
    "History (All Times) - Popular Top", // Title for the history container component
    null, // An optional parameter that is not currently utilized for this specific call
    true, //Flag indicating specific behavior-- perhaps that it's for all-time history??
  );
  return item; // Returning the result from HistoryContainerFct
}

export default HistoryAllTimesFct; // Exporting HistoryAllTimesFct as the default component
