export default function correctSortOrders(oriList) { //Function to correct the sort orders of elements in a list
  let newPockets = [];
  for (let index = 0; index < oriList.length; index++) { //Iterate through each element in the original list
    const element = oriList[index];
    element.sort_order = index + 1; //Update the sort order of the current element to match its index position +1  
    newPockets[index] = element; //Store the updated element in the new array at the corresponding index
  }

  return newPockets; //Return the new array with corrected sort orders
}
