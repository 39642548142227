import React, { Component } from "react";

// Display props within a styled div if props is not null, otherwise, it render an empty div
class LangComp extends Component {
  // Constructor method to initialize state
  constructor(props) {
    super(props); // Call the parent class's constructor
    this.state = { data: props }; // Initialize state with props
  }

  // Render method to define what the component displays
  render() {
    // Check if 'props' data exists
    if (this.state.data.props != null) {
      return ( // If 'props' data exists, return a div with class 'originContent'
        <div className="originContent">
          {"[ "} {this.state.data.props} {" ]"}
        </div>
      );
    } else { // If 'props' data is null, return an empty div
      return <div></div>;
    }
  }
}

export default LangComp;
