import HistoryIconsFct from "./HistoryIconsFct";
import axios from "axios"; // Import Axios
import HistoryIcon from "@mui/icons-material/History";
import React, { useState, useEffect, useContext } from "react";
import { WordContext } from "../Contexts/WordContext";

function HistoryContainerFct(URL, title) {
  const { word, setWord } = useContext(WordContext); // Accessing word and setWord from WordContext
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling
  const [data, setData] = useState([]); // State for storing fetched data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL); // Fetching data from the provided URL using Axios
        setData(response.data); // Setting fetched data to state
        setLoading(false); // Setting loading state to false once data is fetched
      } catch (error) {
        setError(error); // Handling and setting error state if request fails
        setLoading(false); // Setting loading state to false on error
      }
    };

    fetchData(); // Calling fetchData function on component mount

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [URL, word]); // Dependencies include URL and word from context

  if (loading)
    return (
      <div>
        <div className="title">{title}</div>
        <div style={{ width: "100%" }} className="margin-mid mid-font">
          Loading {title}...
        </div>
      </div>
    );

  if (error !== null) {
    console.log("error:" + error);
  }

  if (error) {
    return (
      <div>
        <h3>Error: {error.message}</h3>
        <h3>{error.stack}</h3>
      </div>
    );
  }

  // Function to calculate scale based on maximum count in history data
  function calculateScale() {
    let histArray = data;

    let maxCount = 0;
    for (let item of histArray) {
      if (item.count > maxCount) {
        maxCount = item.count;
      }
    }

    let scale = maxCount / 5.0;
    if (scale > 1) {
      scale = 1 / scale;
    }
    return scale;
  }

  let infoMsg = <div></div>; // Initializing info message JSX variable
  let historyList = <tr></tr>; // Initializing history list JSX variable

  // Conditionally rendering based on data availability
  if (data === null || data.length === 0) {
    infoMsg = <div>No entries yet !</div>; // Message when no data entries are present
  } else if (data !== null && data.length > 0) {
    let scale = calculateScale(); // Calculating scale based on data

    // Mapping through data to create history list JSX
    historyList = data.map((item) => (
      <tr key={item.id}>
        <td
          style={{
            textAlign: "right",
            width: "50%",
            padding: "0px 20px 0px 0px",
          }}
        >
          <div className="margin-mid mid-font">{item.word}</div>
        </td>
        <td
          style={{
            textAlign: "left",
            width: "50%",
            padding: "0px 0px 0px 20px",
          }}
        >
          <HistoryIconsFct id={item.id} count={item.count} scale={scale} />
        </td>
      </tr>
    ));
  } else {
    infoMsg = <div>Something went wrong !!</div>;
  }
  // Returning JSX with title, history list table, and info message
  return (
    <div>
      <div
        className="title flex-row"
        style={{ justifyContent: "space-between" }}
      >
        <div>{title}</div>
        <HistoryIcon />
      </div>

      <table className="margin-mid mid-font">
        <tbody>{historyList}</tbody>
      </table>

      <div className="margin-mid mid-font">{infoMsg}</div>
    </div>
  );
}

export default HistoryContainerFct;
