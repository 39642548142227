import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import LangContextProvider from "./Components/Contexts/LangContext";
import MainContainerFct from "./Components/Meanings/MainContainerFct";
import ModalContextProvider from "./Components/Contexts/ModalContext";
import UserContextProvider from "./Components/Contexts/UserContext";
import WordContextProvider from "./Components/Contexts/WordContext";
import { useEffect } from "react";

const helmetContext = {};

function AppFct() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <HelmetProvider context={helmetContext}> {/* Wrap with HelmetProvider */}
    <UserContextProvider>
      <WordContextProvider>
        <ModalContextProvider>
          <LangContextProvider>
            <MainContainerFct />
          </LangContextProvider>
        </ModalContextProvider>
      </WordContextProvider>
    </UserContextProvider>
    </HelmetProvider>
  );
}

export default AppFct;
