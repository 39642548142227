import axios from "axios";
import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../Contexts/UserContext";
import Cookies from "js-cookie";
import APIURLMaker from "../APIURLMaker";

/* 
Provides a login form, handles user authentication, manages authentication tokens with cookies,
 and allows users to log in and log out 
*/
function NewLoginForm() {
  const [login_id, setLoginId] = useState("admin"); // Initialize login ID state
  const [password, setPassword] = useState("newpass"); // Initialize password state
  const [failedLogin, setFailedLogin] = useState(false); // Initialize failed login state
  const { user, setUser } = useContext(UserContext); // Access user context
  const inputRef = useRef(null); // Create a ref for the password input

  useEffect(() => {
    const savedToken = Cookies.get("yakinuser"); // Retrieve saved token from cookies
    if (!user && savedToken) {
      setUser({ token: savedToken }); // Set user state if token exists
    }
  }, [setUser]); // Dependency array ensures this runs only when setUser changes

  useEffect(() => {
    if (user && user.token) {
      Cookies.set("yakinuser", user.token, {
        secure: true,
        sameSite: "Strict",
      }); // Save token in cookies if user is logged in
    }
  }, [user]); // Dependency array ensures this runs only when user changes

  const handleLogin = async () => {
    if (login_id && password) {
      const URL = APIURLMaker("auth/login"); // Generate login URL
      const body = { login_id: login_id, password: password }; // Create request body

      try {
        const response = await axios.post(URL, body, { withCredentials: true }); // Make POST request to login
        const data = response.data;

        if (data.jwt_token) {
          setUser(data); // Set user state with response data
          setFailedLogin(false); // Reset failed login state
        } else {
          setFailedLogin(true); // Set failed login state if no token received
        }
      } catch (error) {
        console.error("Login error: ", error); // Set failed login state on error
        setFailedLogin(true);
      }
    }
  };

  const handleLogout = () => {
    setLoginId(""); // Reset login ID state
    setPassword(""); // Reset password state
    setUser(null); // Clear user state
    setFailedLogin(false); // Reset failed login state
    Cookies.remove("yakinuser"); // Remove token from cookies
  };

  useEffect(() => {
    if (failedLogin) {
      inputRef.current.focus(); // Focus on password input if login failed
    }
  }, [failedLogin]); // Dependency array ensures this runs only when failedLogin changes

  return user === null ? ( // Render login form if user is not logged in
    <div>
      <div className="title">Login</div>
      <div className="flex-row">
        <input
          id="login_id"
          type="text"
          placeholder="Username..."
          className="edit-box margin-all"
          value={login_id}
          autoComplete="new-user"
          onChange={(e) => setLoginId(e.target.value)} // Update login ID state on input change
        />
        <input
          id="password"
          type="password"
          ref={inputRef}
          placeholder="Password..."
          className="edit-box margin-all"
          value={password}
          autoComplete="new-pass"
          onChange={(e) => setPassword(e.target.value)} // Update password state on input change
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin(); // Trigger login on Enter key press
            }
          }}
        />
        {failedLogin && (
          <div className="error-text">Unsuccessful login, try again!</div> // Show error message if login failed
        )}
        <button className="btn-style-active flex-row" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  ) : (
    <div> {/* Render logout button if user is logged in */}
      <div className="title">Login</div>
      <div className="flex-row margin-all">
        <div className="mid-font flex-row margin-all">
          User ID: {user.login_id}
        </div>
        <button className="btn-style-active flex-row" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default NewLoginForm;
