import "../../styles/new.css";
import { Row } from "react-bootstrap";
import WordTypeComp from "./WordTypeComp";
import LangComp from "./LangComp";
import MeaningLangsContainer from "./MeaningLangContainer";

// Shows a meaning's type, content in different languages, and related details
function MeaningItemFct(props) {
  let state = props.data; // Retrieves data from props and assigns it to the state variable

  return (
    <div className="meaning-block"> {/* Container for the meaning item. */}
      <Row style={{ display: "flex" }}>
        <WordTypeComp props={state.word_type} /> {/* Render WordTypeComp with word_type prop */}
        <LangComp props={state.lang_content} /> {/* Render LangComp with lang_content prop */}
        <div style={{ flexGrow: "1" }}></div> {/* Flex grow element for spacing. */}
        {/*<button className="exampleButton">Examples</button>*/}
      </Row>
      <Row className="meaning-row">
        <div className="meaning-div">
          <div className="meaning-id">{state.meaning_id}.</div> {/* Displays meaning ID. */}
          <div className="meaning">{state.meaning}.</div> {/* Displays meaning text. */}
        </div>
      </Row>

      <div>
        <MeaningLangsContainer
          chapter_id={state.chapter_id}
          meaning_id={state.meaning_id}
        /> {/* Render MeaningLangsContainer with chapter_id and meaning_id props */}
      </div>
    </div>
  );
}

export default MeaningItemFct;
