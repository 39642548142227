import MeaningItemFct from "./MeaningItemFct.js";

// Display a chapter's name, syllables with bullet points, and a list of meanings
function ChapterCompFct(props) {
  const name = props.name; // Extract the chapter name from props
  const syls = props.syls; // Extract the syllables from props
  const slysNew = syls.replaceAll(".", String.fromCharCode(8226)); // Replace periods with bullet points in syllables
  const meanings = props.meanings; // Extract the meanings array from props
  
  // Create a list of MeaningItemFct components for each meaning
  const meaningList = meanings.map((item) => (
    <MeaningItemFct key={item.id} data={item} />
  ));
  return (
    <div className="title">
      <div style={{ marginLeft: "10px" }}>
        {name} [ {slysNew} ]
      </div>
      <div>{meaningList}</div>
      <div style={{ marginBottom: "10px" }}></div>
    </div>
  );
}

export default ChapterCompFct;
