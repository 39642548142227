import ChampsContainerFct from "./ChampsContainerFct";
import APIURLMaker from "../APIURLMaker";

//Use ChampsContainerFct to fetch and display "Champions of All Times" data
function ChampsAllTimesFct(props) {
  let item = ChampsContainerFct(
    APIURLMaker("champs/GetChampsAllTimes"), //Generate the URL endpoint for the URL parameter of the function
    "Champions of All Times", //Title parameter
    null, //_words parameter
  );
  return item;
}

export default ChampsAllTimesFct;
