import ChampIconsFct from "./ChampsIconsFct";
import axios from "axios"; // Import Axios - library to make http requests to fetch data from a URL with optional params
import { useEffect, useState } from "react"; //Import React hooks for managing state and side effects
import { v4 as uuid_v4 } from "uuid"; //Import a function from the uuid library to generate unique keys for elements
import Cup from "@mui/icons-material/EmojiEvents"; //Import cup icon from Material-UI
import React from "react";

//Manage the fetching and rendering of champs data, handle loading and error states, dynamically render content based on the fetched data
function ChampsContainerFct(URL, title, _word) {
  const [loading, setLoading] = useState(true); //Track whether data is being fetched
  const [error, setError] = useState(null); //Track any error during fetching
  const [data, setData] = useState([]); //Store the fetched data

  //React hook to perform side effects 
  useEffect(() => {
    //Asynchronous function to fetch data from URL using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(URL, { word: _word });
        setData(response.data); //Update the data with fetched response
        setLoading(false); //Set loading state to false when loading is complete
      } catch (error) {
        setError(error); //Set error state if an error occurs
        setLoading(false);
      }
    };

    fetchData(); //Run and fetch the data when the component mounts or if URL or _word changes

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [URL, _word]);

  //Render loading message if loading state is true
  if (loading)
    return (
      <div>
        <div
          className="title flex-row"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">{title}</div>
          <Cup />
        </div>
        <div className="margin-mid small-font">Loading Champs...</div>
      </div>
    );

  if (error !== null) {
    console.log("error:" + error);
  }

  //Render error message if error state is not null
  if (error) {
    return (
      <div>
        <h3>Error: {error.message}</h3>
        <h3>{error.stack}</h3>
      </div>
    );
  }

  let infoMsg = <div></div>;
  let champsList = <tr></tr>;

  //Calculate a scaling factor based on the maximum count value in "data"
  function calculateScale() {
    let champsArray = data;

    let maxCount = 0;
    for (let item of champsArray) {
      if (item.count > maxCount) {
        maxCount = item.count;
      }
    }

    let scale = maxCount / 5.0;
    if (scale > 1) {
      scale = 1 / scale;
    }
    return scale;
  }

  //Render appropriate message if data is empty
  if (data === null || data.length === 0) {
    infoMsg = <div>No entries yet !</div>;
  } else if (data !== null && data.length > 0) {
    let scale = calculateScale();

    //Render table rows with user info and ChampIconsFct component
    champsList = data.map((item) => (
      <tr key={uuid_v4()}>
        <td
          style={{
            textAlign: "right",
            width: "50%",
            padding: "0px 20px 0px 0px",
          }}
        >
          <div className="mid-font">
            {item.update_userid} [ {item.count} ]
          </div>
        </td>
        <td
          style={{
            textAlign: "left",
            width: "50%",
            padding: "0px 0px 0px 20px",
          }}
        >
          <ChampIconsFct id={uuid_v4()} count={item.count} scale={scale} />
        </td>
      </tr>
    ));
  } else {
    infoMsg = <div>Something went wrong !!</div>;
  }

  //Return elements (title, cup icon, champsList, infoMsg) based on the current state 
  return (
    <div>
      <div
        className="title flex-row"
        style={{ justifyContent: "space-between" }}
      >
        <div>{title}</div>
        <Cup />
      </div>
      <table style={{ width: "100%" }} className="margin-mid">
        <tbody>{champsList}</tbody>
      </table>

      <div style={{ width: "100%" }} className="margin-mid">
        {infoMsg}
      </div>
    </div>
  );
}

export default ChampsContainerFct;
