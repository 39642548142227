import React from "react";
import Bright from "@mui/icons-material/Brightness4Outlined";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Medal from "../../Images/medal_small.png";

//Function to dynamically generate and display the stars or icons that the user has
function ChampsIconsFct(props) {
  const { count, scale } = props; //Destructure props to extract count and scale
  let stars = Math.round(count * scale); //Calculate the number of stars based on count and scale

  const items = []; //Create an array to hold JSX elements based on stars

  //Determine the color based on the number of stars
  let color = "red";
  switch (stars) {
    case 0:
      color = "green";
      break;
    case 1:
      color = "blue";
      break;
    case 2:
      color = "orange";
      break;
    case 3:
      color = "red";
      break;
    case 4:
      color = "magenta";
      break;
    case 5:
      color = "#550A21";
      break;
    default:
      break;
  }


  //Push icons or images into the items array
  //If no stars, display ArrowRightAlt icon
  if (stars === 0) {
    items.push(
      <ArrowRightAlt style={{ color: color }} key={0} fontSize="small" />,
    );

    //Else, loop to push Bright icons or Medal image based on the value of stars
  } else {
    for (let i = 0; i < stars; i++) {
      if (stars === 5) {
        items.push(<img src={Medal} key={i} alt="Medal" />);
      } else {
        items.push(
          <Bright style={{ color: color }} key={i} fontSize="small" />,
        );
      }
    }
  }

  //Return a div element containing the items array according to the conditions above
  return <div>{items}</div>;
}

//Export the ChampsIconsFct as default export from this module to make this component available for use in other modules 
export default ChampsIconsFct;  
