import React from "react";
import ReactDOM from "react-dom/client";
import AppFct from "./AppFct";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
if (rootElement === null)
  throw new Error("Root container missing in index.html");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AppFct />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
