import { Grid, Paper } from "@mui/material"; //Layout and styling
import Library from "@mui/icons-material/LocalLibrary"; //Library icon
import Chain from "@mui/icons-material/Link"; //Link or chain icon
import FLF from "../ForeignLists/FLF"; //
import YakinTabFct from "../YakinTab/YakinTabFct";
import NewLoginForm from "../User/NewLoginForm";
import React, { useState, useEffect, useRef, useContext } from "react"; //React hooks for state management, side effects, referencing DOM elements, accessing context values
import { WordContext } from "../Contexts/WordContext"; //React context to provide the "word" value and a function to set it

// Provide a structured layout to display a login form, thesaurus chain, English library section
function SupportingDataColumn() {
  const { word, setWord } = useContext(WordContext);
  //console.log("SupportingDataColumn");
  var content = (
    <div className="grid">
      <Paper className="paper">
        <NewLoginForm />
        <div
          className="title flex-row"
          style={{ justifyContent: "space-between" }}
        >
          Thesarus Chain
          <div style={{ flexGrow: 0.2 }}></div>
          {/* <Chain /> */}
        </div>
        <YakinTabFct word={word} />
        <div
          className="title flex-row"
          style={{ justifyContent: "space-between" }}
        >
          English Library
          <div style={{ flexGrow: 0.2 }}></div>
          <Library />
        </div>
        <FLF word={word} />
      </Paper>
    </div>
  );

  return content;
}

export default SupportingDataColumn;
