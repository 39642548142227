import React, { useState, useEffect, useContext } from "react";
import axios from "axios"; // Import Axios
import APIURLMaker from "../APIURLMaker";
import SearchResultItem from "./SearchResultItem";
import { WordContext } from "../Contexts/WordContext";

// Handles fetching and displaying search results based on a provided word
function ShowWordLikesFct() {
  const { word, setWord } = useContext(WordContext); // Access current word and setter function from context
  const [loading, setLoading] = useState(false); // State to track loading state
  const [error, setError] = useState(null); // State to hold error information
  const [data, setData] = useState(null); // State to hold fetched data

  useEffect(() => {
    const fetchData = async () => { //Async function to fetch data
      setLoading(true);
      try {
        const URL = APIURLMaker(`words/findLikes`); // Create API URL for fetching similar words
        const body = { word: `${word}`, benchmark: 0.9 }; // Request body containing the word and benchmark
        const response = await axios.post(URL, body); // Perform POST request to fetch similar words
        setData(response.data); // Set fetched data
        setLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        setError(error); // Set error state if an error occurs
        setLoading(false); // Set loading state to false on error
      }
    };

    fetchData(); // Call fetchData function when component mounts or word changes

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [word]); // Depend on 'word' to refetch data whenever it changes

  // Conditional rendering based on word, loading state, error, and fetched data
  if (word !== null && word !== "") {
    if (loading) return <div>..Loading meanings for {word}...</div>; // Show loading message while fetching data
    if (error) {
      return (
        <div>
          <h3>Error: {error.message}</h3>
          <h3>{error.stack}</h3>
        </div>
      ); // Display error message and stack trace if an error occurs
    }

    if (data !== null && data.length > 0) {
      return (
        <div
          className="title"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            borderStyle: "solid",
          }}
        >
          {/* Map over fetched data to render SearchResultItem for each result */}
          {data.map((item, index) => (
            <SearchResultItem
              key={index}
              word={item.word}
              distance={item.distance}
              setSearchWord={setWord} // Pass setSearchWord function to handle click event
            />
          ))}
        </div>
      ); // Render SearchResultItem components for each fetched result
    } else {
      return <div className="mid-font">No similar words or phrases ! </div>; // Display message when no similar words are found
    }
  } else {
    return <div className="mid-font">Word / Meanings not found ! </div>; // Display message when word is null or empty
  }
}

export default ShowWordLikesFct;
