import APIURLMaker from "../APIURLMaker";
import HistoryContainerFct from "./HistoryContainerFct";

function HistoryThisWeekFct(props) {
  // Call HistoryContainerFct with specific parameters and assign the result to item
  let item = HistoryContainerFct(
    APIURLMaker("history/GetHistoryThisWeek"), // Create URL for fetching this week's history
    "History (ThisWeek) - Popular Top", // Title for the history container
    null, // Additional parameters (null in this case)
    true, // Flag indicating it's for this week's history
  );
  return item; // Return the item created by HistoryContainerFct
}

export default HistoryThisWeekFct; // Export the HistoryThisWeekFct function as the default export
