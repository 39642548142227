import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import HistoryTodayFct from "../History/HistoryTodayFct";
import HistoryThisWeekFct from "../History/HistoryThisWeekFct";
import HistoryAllTimesFct from "../History/HistoryAllTimesFct";
import { Grid, Paper } from "@mui/material";
import ChampsAllTimesFct from "../Champs/ChampsAllTimesFct";
import { WordContext } from "../Contexts/WordContext";
import React, { useContext } from "react";


//Fetch and display historical and champion related data
function HistoryColumn() {
  const { word } = useContext(WordContext);
  var content = (
    <div className="grid">
      <Paper className="paper">
        <ChampsAllTimesFct />
        <HistoryTodayFct word={word} />
        <HistoryThisWeekFct />
        <HistoryAllTimesFct />
      </Paper>
    </div>
  );

  return content;
}

export default HistoryColumn;
